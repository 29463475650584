import React from 'react'
import { verifyBannerContent } from '../../data/verifyContent'
import { useModal } from '../ContactForm'

export const VerifyBanner = () => {
  const { modalAction } = useModal();
  return (
      <section className='bg-[#0361FE] flex flex-col lg:flex-row lg:items-center lg:h-[550px] 2xl:h-[680px] px-4 lg:px-16 pb-10 lg:pb-0'>
          <div className='lg:w-1/2 text-white space-y-6 my-8 lg:my-0'>
              <h1 className='text-[40px] xl:text-[48px] font-[700] text-black'>{verifyBannerContent.title.blackColor}<span className='text-white'>{verifyBannerContent.title.whiteColor}</span></h1>
              <p className='leading-[30px] lg:w-11/12'>{verifyBannerContent.paraLine}</p>
              <button className='px-6 py-4 bg-white text-black rounded-[48px]' onClick={modalAction}>Book a Demo</button>
          </div>
          <img src={verifyBannerContent.image} alt="verifyBannerImage" className='lg:w-1/2 rounded-[8px]'/>
    </section>
  )
}
