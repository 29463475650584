import React from 'react'
import { useModal } from '../ContactForm'
import { FooterContactBox } from '../FooterContactBox'
import { VerifyBanner } from './VerifyBanner'
import { VerifySectionFour } from './VerifySectionFour'
import { VerifySectionThree } from './VerifySectionThree'
import { VerifySectionTwo } from './VerifySectionTwo'

export const VerifyPage = () => {
  const { modalAction } = useModal();
  return (
      <div>
          <VerifyBanner />
          <VerifySectionTwo />
          <VerifySectionThree />
          <VerifySectionFour />
          <FooterContactBox>
            <div className='p-4 lg:p-0 lg:w-2/3'>
                <h5 className='font-[700] text-[30px] lg:text-[38px]'>We are here to help</h5>
                <p className='font-[400] text-[18px] py-3'>Hire us before hiring your employees to ensure a safe and secure workforce!</p>
                <button className='bg-[#0361FE] text-white py-[11px] px-[25px] rounded-[48px] mt-5' onClick={modalAction}>Contact us</button>

           </div>
          </FooterContactBox>
    </div>
  )
}
